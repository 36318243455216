import { isMobile } from '../utils/index'
export default {
  address: '台北市文山區仙岩路16巷32號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3616.0773765100757!2d121.5492322!3d24.9974866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa0fe568f589%3A0x93d301522de5cebe!2zMTE25Y-w5YyX5biC5paH5bGx5Y2A5LuZ5bKp6LevMTblt7czMuiZnw!5e0!3m2!1szh-TW!2stw!4v1610444094269!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/NnSKHBWtj54jKc4g7',
  phone: '02-89322888',
  fbLink: 'https://www.facebook.com/%E9%80%A3%E9%9B%B2%E6%99%AF%E7%B2%8B-105504811458953',
  fbMessage: 'https://m.me/105504811458953',
  caseName: '連雲景粋',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '連雲建築・展曜建設股份有限公司'],
    ['建築設計', '吉村靖孝建築設計事務所'],
    ['建築代銷', '聯碩行銷股份有限公司'],
   // ['建照號碼', ' '],
    ['建築規劃', '吳興宇建築師事務所'],
    ['營建施工', '展瑞營造'],
    ['建案位置', '文山區仙岩路16巷32號、仙岩公園首排'],
    ['經紀人', '徐福龍 (91) 北市經證字第00848號'],
    ['建照號碼', '110建字第0056號'],
  ],

  gtmCode: ['MGNM9FV','M7L2SK7'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約賞屋' : '預約賞屋',
    subTitle: '連雲建築的精質手工  日本職人的講究房子<br /><br />即將靜好公開 敬請期待  歡迎預約優先參觀品賞'
  }
}
